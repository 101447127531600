<template>
  <div id="terminals-list">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="EPayTerminals">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-input label="Search" v-model="searchText"/>
          <div class="flex justify-center ml-4" style="place-items: end;">
            <vs-button class="mt-5" @click="searchRequests">{{ $t('Search') }}</vs-button>
          </div>
          <div class="flex justify-center ml-4" style="place-items: end;">
            <vs-button class="mt-5" @click="downloadTerminalsList">{{ $t('Download') }}</vs-button>
          </div>
        </div>
      </template>
    </breadcrumb-base>


    <!-- Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">

      <div class="vx-row">
        <div class="w-full">
          <!-- Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="terminals.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="terminals.length > 0 && !errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             :data="terminals">

<!--            1. Organisation[first_name, last_name] and customer_id [parent] &ndash;&gt; hover [organisation_name]-->
<!--            2. seller [first_name, last_name] and customer_id [seller] &ndash;&gt; hover [organisation_name]-->
<!--            3. address and house_no [from seller]-->
<!--            4. postal code and city  [from seller]-->
<!--            5. stnr and ustldnr [from seller]-->
<!--            6. terminal ID [from seller]-->
            <template slot="thead">
              <vs-th >{{$t('Reseller')}}</vs-th>
              <vs-th >{{$t('ShopName')}}</vs-th>
              <vs-th >{{$t('Address')}}</vs-th>
              <vs-th >
                <div class="vs-col">
                  <p class="">{{ $t('City') }}</p>
                  <p class="">{{ $t('PostalCode') }}</p>
                </div>
              </vs-th>
              <vs-th >{{$t('TerminalId')}}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <div class="tooltip text-normal">
                    <div class="">
                      <p class="user-name font-medium truncate">{{ tr.parent_organisation.first_name }} {{ tr.parent_organisation.last_name }}</p>
                      <p class="user-name font-medium truncate">{{ tr.parent_organisation.customer_id }}</p>
                    </div>
                    <span class="tooltiptext tooltip-top">{{ tr.parent_organisation.organisation_name }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="tooltip text-normal">
                    <div class="">
                      <p class="user-name font-medium truncate">{{ tr.seller.first_name }} {{ tr.seller.last_name }}</p>
                      <p class="user-name font-medium truncate">{{ tr.seller.customer_id }}</p>
                    </div>
                    <span class="tooltiptext tooltip-top">{{ tr.seller.organisation_name }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="">
                    {{ tr.seller.address }}<span v-if="tr.seller.house_no"> {{ tr.seller.house_no }}</span>
                  </p>
                </vs-td>
                <vs-td>
                  <div class="vs-col">
                    <p class="font-medium truncate">{{ tr.seller.city }}</p>
                    <p class="font-medium truncate">{{ tr.seller.postal }}</p>
                  </div>
                </vs-td>
                <vs-td>
                  <p class="">
                    {{ tr.seller.terminal_id }}
                  </p>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex" :key="cPage">
                <vs-new-pagination :total="vTotalPages" :value="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'EPayTerminalsList',
  data() {
    return {
      cPage: 1,
      itemsPerPage: 20,
      searchText: '',
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'EPay Terminals', i18n: 'EPayTerminals', active: true },
      ],
    }
  },
  computed: {
    terminals() {
      return this.$store.state.ePayTerminals.terminals
    },
    vPage() {
      return this.$store.state.ePayTerminals.vPage
    },
    vTotalPages() {
      return this.$store.state.ePayTerminals.vTotalPages
    },
  },
  methods: {

    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    listPageChanged(item) {
      if(!isNaN(item)) {
        this.cPage = item;
        this.fetchTerminalsList(this.cPage - 1)
      }
    },
    downloadTerminalsList() {
      this.$vs.loading()
      const filter = {
        'page': '0',
        'search_text': this.searchText || '',
      };
      filter.download = 1
      this.$store.dispatch('ePayTerminals/downloadEPayTerminalsList', {payload: filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false

          const hiddenElement = document.createElement('a')
          hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
          hiddenElement.target = '_blank'
          hiddenElement.download = 'terminals.csv'
          hiddenElement.click()
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    searchRequests() {
      this.fetchTerminalsList('0', this.searchText)
    },
    fetchTerminalsList(page = '0', search = '') {
      if (isNaN(page)) {
        return
      }
      const payload = {
        'page': page,
        'search_text': search,
      }
      this.$vs.loading()
      this.$store.dispatch('ePayTerminals/fetchEPayTerminalsList', { payload })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
}
</script>

<style scoped lang="scss">
#terminals-list {

}
</style>
